<template>
  <div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-start justify-content-end py-1">
        <b-col md="12" class="px-3 py-1">
          <h3 class="font-weight-bolder text-colorBlack">Licenses</h3>
          <p class="font-weight-bold text-colorGray mb-50">
            All Licenses listed here
          </p>
        </b-col>
      </b-row>
      <b-row class="px-1 w-100">
        <b-col md="12">
          <b-tabs content-class="mt-50">
            <b-tab class="pl-1" active>
              <template #title>
                <feather-icon icon="SearchIcon" />
                <span class="font-weight-bolder text-colorBlack"
                  >Student Licenses</span
                >
              </template>
              <b-row class="d-flex align-items-center justify-content-start">
                <b-col md="12" class="py-50">
                  <h3 class="font-weight-bolder text-colorBlack m-0">
                    Student Licenses
                  </h3>
                </b-col>
              </b-row>

              <b-card class="mt-2" style="border-radius: 20px">
                <b-table
                  stacked="md"
                  :fields="fieldsStudentLicense"
                  :items="StudentLicense"
                  thead-class="tableHeadColor"
                  small
                  rounded
                >
                  <template #head()="data">
                    <div class="py-50">
                      <span class="font-weight-bolder text-colorBlack">{{
                        data.label
                      }}</span>
                    </div>
                  </template>

                  <template #cell(LicensesName)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.LicensesName ? row.item.LicensesName : "" }}
                        <br />
                      </span>
                    </div>
                  </template>
                  <template #cell(PONo)="row">
                    <span
                      class="font-weight-bolder text-colorBlack m-0"
                      style="font-size: 0.8rem"
                    >
                      {{ row.item.PONo ? row.item.PONo : "" }}
                    </span>
                  </template>

                  <template #cell(INVNo)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.INVNo ? row.item.INVNo : "" }}
                      </span>
                    </div>
                  </template>
                  <template #cell(Student)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.Student ? row.item.Student : "" }}
                      </span>
                    </div>
                  </template>

                  <template #cell(ExpiryDate)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.ExpiryDate ? row.item.ExpiryDate : "" }}
                      </span>
                    </div>
                  </template>

                  <template #cell(MonthlySubscription)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{
                          row.item.MonthlySubscription
                            ? row.item.MonthlySubscription
                            : ""
                        }}
                      </span>
                    </div>
                  </template>

                  <template #cell(manage)="">
                    <b-nav-item-dropdown
                      right
                      no-caret
                      style="list-style-type: none"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          class="font-weight-bolder mr-50 text-colorBlack"
                          size="30"
                        />
                      </template>
                      
                      <b-dropdown-item href="#">
                        <b-row class="d-flex align-items-center">
                          <b-col>
                            <span class="font-weight-bolder text-colorBlack">
                              <feather-icon
                                icon="XCircleIcon"
                                class="font-weight-bolder mr-50 text-colorBlack"
                                size="16"
                              />
                              Revoke
                            </span>
                          </b-col>
                        </b-row>
                      </b-dropdown-item>
                    </b-nav-item-dropdown>
                  </template>
                </b-table>
              </b-card>
            </b-tab>
            <b-tab class="pl-1">
              <template #title>
                <feather-icon icon="SearchIcon" />
                <span class="font-weight-bolder text-colorBlack"
                  >Teacher Licenses</span
                >
              </template>
              <b-row class="d-flex align-items-center justify-content-start">
                <b-col md="12" class="py-50">
                  <h3 class="font-weight-bolder text-colorBlack m-0">
                    Teacher Licenses
                  </h3>
                </b-col>
              </b-row>
              <b-card class="mt-2" style="border-radius: 20px">
                <b-table
                  stacked="md"
                  :fields="fieldsStudentLicense"
                  :items="StudentLicense"
                  thead-class="tableHeadColor"
                  small
                  rounded
                >
                  <template #head()="data">
                    <div class="py-50">
                      <span class="font-weight-bolder text-colorBlack">{{
                        data.label
                      }}</span>
                    </div>
                  </template>

                  <template #cell(LicensesName)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.LicensesName ? row.item.LicensesName : "" }}
                        <br />
                      </span>
                    </div>
                  </template>
                  <template #cell(PONo)="row">
                    <span
                      class="font-weight-bolder text-colorBlack m-0"
                      style="font-size: 0.8rem"
                    >
                      {{ row.item.PONo ? row.item.PONo : "" }}
                    </span>
                  </template>

                  <template #cell(INVNo)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.INVNo ? row.item.INVNo : "" }}
                      </span>
                    </div>
                  </template>
                  <template #cell(Student)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.Student ? row.item.Student : "" }}
                      </span>
                    </div>
                  </template>

                  <template #cell(ExpiryDate)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.ExpiryDate ? row.item.ExpiryDate : "" }}
                      </span>
                    </div>
                  </template>

                  <template #cell(MonthlySubscription)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{
                          row.item.MonthlySubscription
                            ? row.item.MonthlySubscription
                            : ""
                        }}
                      </span>
                    </div>
                  </template>

                  <template #cell(manage)="">
                    <b-nav-item-dropdown
                      right
                      no-caret
                      style="list-style-type: none"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          class="font-weight-bolder mr-50 text-colorBlack"
                          size="30"
                        />
                      </template>
                      
                      <b-dropdown-item href="#">
                        <b-row class="d-flex align-items-center">
                          <b-col>
                            <span class="font-weight-bolder text-colorBlack">
                              <feather-icon
                                icon="XCircleIcon"
                                class="font-weight-bolder mr-50 text-colorBlack"
                                size="16"
                              />
                              Revoke
                            </span>
                          </b-col>
                        </b-row>
                      </b-dropdown-item>
                    </b-nav-item-dropdown>
                  </template>
                </b-table>
              </b-card>
            </b-tab>
            <b-tab class="pl-1">
              <template #title>
                <feather-icon icon="SearchIcon" />
                <span class="font-weight-bolder text-colorBlack"
                  >Institute License
                </span>
              </template>
              <b-row class="d-flex align-items-center justify-content-start">
                <b-col md="12" class="py-50">
                  <h3 class="font-weight-bolder text-colorBlack m-0">
                    Institute License
                  </h3>
                </b-col>
              </b-row>
              <b-card class="mt-2" style="border-radius: 20px">
                <b-table
                  stacked="md"
                  :fields="fieldsInstituteLicense"
                  :items="InstituteLicense"
                  details-td-class="m-0 p-0"
                  thead-class="tableHeadColor"
                  small
                  rounded
                >
                  <template #head()="data">
                    <div class="py-50">
                      <span class="font-weight-bolder text-colorBlack">{{
                        data.label
                      }}</span>
                    </div>
                  </template>

                  <template #cell(LicensesName)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.LicensesName ? row.item.LicensesName : "" }}
                        <br />
                      </span>
                    </div>
                  </template>
                  <template #cell(PONo)="row">
                    <span
                      class="font-weight-bolder text-colorBlack m-0"
                      style="font-size: 0.8rem"
                    >
                      {{ row.item.PONo ? row.item.PONo : "" }}
                    </span>
                  </template>

                  <template #cell(INVNo)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.INVNo ? row.item.INVNo : "" }}
                      </span>
                    </div>
                  </template>
                  <template #cell(Student)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.Student ? row.item.Student : "" }}
                      </span>
                    </div>
                  </template>

                  <template #cell(ExpiryDate)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{ row.item.ExpiryDate ? row.item.ExpiryDate : "" }}
                      </span>
                    </div>
                  </template>

                  <template #cell(MonthlySubscription)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        {{
                          row.item.MonthlySubscription
                            ? row.item.MonthlySubscription
                            : ""
                        }}
                      </span>
                    </div>
                  </template>

                  <template #cell(manage)="">
                    <b-nav-item-dropdown
                      right
                      no-caret
                      style="list-style-type: none"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          class="font-weight-bolder mr-50 text-colorBlack"
                          size="30"
                        />
                      </template>
                      
                      <b-dropdown-item href="#">
                        <b-row class="d-flex align-items-center">
                          <b-col>
                            <span class="font-weight-bolder text-colorBlack">
                              <feather-icon
                                icon="XCircleIcon"
                                class="font-weight-bolder mr-50 text-colorBlack"
                                size="16"
                              />
                              Revoke
                            </span>
                          </b-col>
                        </b-row>
                      </b-dropdown-item>
                    </b-nav-item-dropdown>
                  </template>
                </b-table>
              </b-card>
              <b-card class="mt-2" style="border-radius: 20px">
                <b-table
                  stacked="md"
                  :fields="fieldsStudyMaterial2"
                  :items="StudyMaterial2"
                  details-td-class="m-0 p-0"
                  thead-class="tableHeadColor"
                  small
                  rounded
                >
                  <template #head()="data">
                    <div class="py-50">
                      <span class="font-weight-bolder text-colorBlack">{{
                        data.label
                      }}</span>
                    </div>
                  </template>

                  <template #cell(Lecture)="row">
                    <div class="py-1">
                      <span
                        class="font-weight-bolder text-colorBlack m-0"
                        style="font-size: 0.8rem"
                      >
                        <img
                          src="@/assets/images/icons/assignment/pdf.svg"
                          img-fluid
                          width="40px"
                          height="40px"
                          class="mr-50"
                        />
                        {{ row.item.Lecture ? row.item.Lecture : "" }}
                      </span>
                    </div>
                  </template>
                  <template #cell(DateTime)="row">
                    <span
                      class="font-weight-bolder text-colorBlack m-0"
                      style="font-size: 0.8rem"
                    >
                      {{ row.item.DateTime ? row.item.DateTime : "" }}
                    </span>
                  </template>

                  <template #cell(manage)="">
                    <b-button
                      variant="colorBlue"
                      rounded
                      size="sm"
                      class="text-center ml-0 ml-md-4"
                    >
                      <feather-icon icon="UploadIcon" class="mr-50" />
                      Upload File
                    </b-button>
                  </template>
                </b-table>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      option: {},

      fieldsStudentLicense: [
        { key: "LicensesName", label: "Licenses Name" },
        { key: "PONo", label: "PO #" },
        { key: "INVNo", label: "INV #" },
        { key: "Student", label: "Students " },
        { key: "ExpiryDate", label: "Expiry Date " },
        { key: "Status", label: "Monthly Subscription" },
        { key: "manage", label: "" },
      ],
      StudentLicense: [
        {
          LicensesName: " Toronto District School Board",
          PONo: "243434",
          INVNo: "784587",
          Student: "23",
          ExpiryDate: "Expired",
          MonthlySubscription: "$566",
        },
        {
          LicensesName: " Toronto District School Board",
          PONo: "243434",
          INVNo: "784587",
          Student: "23",
          ExpiryDate: "Expired",
          MonthlySubscription: "$566",
        },
        {
          LicensesName: " Toronto District School Board",
          PONo: "243434",
          INVNo: "784587",
          Student: "23",
          ExpiryDate: "Expired",
          MonthlySubscription: "$566",
        },
        {
          LicensesName: " Toronto District School Board",
          PONo: "243434",
          INVNo: "784587",
          Student: "23",
          ExpiryDate: "Expired",
          MonthlySubscription: "$566",
        },
      ],
      fieldsTeacherLicense: [
        { key: "LicensesName", label: "Licenses Name" },
        { key: "PONo", label: "PO #" },
        { key: "INVNo", label: "INV #" },
        { key: "Student", label: "Students " },
        { key: "ExpiryDate", label: "Expiry Date " },
        { key: "MonthlySubscription", label: "Monthly Subscription" },
        { key: "manage", label: "" },
      ],
      TeacherLicense: [
        {
          LicensesName: " Toronto District School Board",
          PONo: "243434",
          INVNo: "784587",
          Student: "23",
          ExpiryDate: "Expired",
          MonthlySubscription: "$566",
        },
        {
          LicensesName: " Toronto District School Board",
          PONo: "243434",
          INVNo: "784587",
          Student: "23",
          ExpiryDate: "Expired",
          MonthlySubscription: "$566",
        },
        {
          LicensesName: " Toronto District School Board",
          PONo: "243434",
          INVNo: "784587",
          Student: "23",
          ExpiryDate: "Expired",
          MonthlySubscription: "$566",
        },
        {
          LicensesName: " Toronto District School Board",
          PONo: "243434",
          INVNo: "784587",
          Student: "23",
          ExpiryDate: "Expired",
          MonthlySubscription: "$566",
        },
      ],
      fieldsInstituteLicense: [
        { key: "LicensesName", label: "Licenses Name" },
        { key: "PONo", label: "PO #" },
        { key: "INVNo", label: "INV #" },
        { key: "Student", label: "Students " },
        { key: "ExpiryDate", label: "Expiry Date " },
        { key: "MonthlySubscription", label: "Monthly Subscription" },
        { key: "manage", label: "" },
      ],
      InstituteLicense: [
        {
          LicensesName: " Toronto District School Board",
          PONo: "243434",
          INVNo: "784587",
          Student: "23",
          ExpiryDate: "Expired",
          MonthlySubscription: "$566",
        },
        {
          LicensesName: " Toronto District School Board",
          PONo: "243434",
          INVNo: "784587",
          Student: "23",
          ExpiryDate: "Expired",
          MonthlySubscription: "$566",
        },
        {
          LicensesName: " Toronto District School Board",
          PONo: "243434",
          INVNo: "784587",
          Student: "23",
          ExpiryDate: "Expired",
          MonthlySubscription: "$566",
        },
        {
          LicensesName: " Toronto District School Board",
          PONo: "243434",
          INVNo: "784587",
          Student: "23",
          ExpiryDate: "Expired",
          MonthlySubscription: "$566",
        },
      ],

      subjectCode: "",
      value: 20,
      max: 100,
    };
  },
  methods: {},
};
</script>

<style>
.tableHeadColor,
.table thead th,
thead,
th {
  background-color: #ebf0fc !important;
}
</style>
